import React, {Component} from 'react';
import {Link, NavLink, withRouter} from "react-router-dom";
import routes from '../routes';
import navigationTranslations from "../translations/navigation";
import {Translate, withLocalize} from "react-localize-redux";

class Navigation extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      showNavigation: false
    };

    this.props.addTranslation(navigationTranslations);

    this.toggleMenu = this.toggleMenu.bind(this);
    this.onRouteChanged = this.onRouteChanged.bind(this);

    props.history.listen((location, action) => {
      this.onRouteChanged();
    });
  }

  onRouteChanged() {
    this.setState({showNavigation: false});
  }

  toggleMenu() {
    this.setState({showNavigation: !this.state.showNavigation});
    console.log(this.state.showNavigation);
  }

  render() {

    let show = '';
    if (this.state.showNavigation) {
      show = 'show';
    }

    return (
      <section className="mainmenu-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <nav className="main-menu">
                <div className="navbar-header">
                  <button type="button"
                          className="navbar-toggle"
                          onClick={this.toggleMenu}>
                    <span className="icon-bar"/>
                    <span className="icon-bar"/>
                    <span className="icon-bar"/>
                  </button>
                </div>
                <div className={"navbar-collapse collapse clearfix " + show}>
                  <ul className="navigation clearfix">
                    <li><NavLink exact to={routes.home}><Translate id="navigation.homepage"/></NavLink></li>
                    <li><NavLink to={routes.services}><Translate id="navigation.services"/></NavLink></li>
                    <li><NavLink to={routes.history}><Translate id="navigation.history"/></NavLink></li>
                    <li><NavLink to={routes.jobs}><Translate id="navigation.jobs"/></NavLink></li>
                    <li><NavLink to={routes.contact}><Translate id="navigation.contact"/></NavLink></li>
                  </ul>
                </div>
              </nav>
              {false &&
              (<div className="appointment-button">
                <Link to={routes.appointment}><Translate id="navigation.appointment"/></Link>
              </div>)
              }
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(withLocalize(Navigation));

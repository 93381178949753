import React from 'react';
import SliderComponent from "./SliderComponent";

export function HomePage() {
  return (
    <div>
      <SliderComponent/>
    </div>
  );
}

import React, {Component} from 'react';
import {Switch} from 'react-router-dom';
import ScrollToTopRoute from "../components/ScrollToTopRoute";
import {HomePage} from "./home_page/HomePage";
import ContactPage from "./contact/ContactPage";
import ServicesRoutes from "./services/ServicesRoutes";
import routes from "../routes";
import AppointmentPage from "./appointment/AppointmentPage";
import HistoryPage from "./HistoryPage";
import NotFoundPage from "./NotFoundPage";
import LegalPage from "./LegalPage";
import JobsPage from "./JobsPage";

class RouterComponent extends Component {

  render() {
    return (
      <div>
        <Switch>
          <ScrollToTopRoute exact path={routes.home} component={HomePage}/>

          <ScrollToTopRoute path={routes.services} component={ServicesRoutes}/>

          <ScrollToTopRoute exact path={routes.contact} component={ContactPage}/>

          <ScrollToTopRoute exact path={routes.history} component={HistoryPage}/>

          <ScrollToTopRoute exact path={routes.jobs} component={JobsPage}/>

          <ScrollToTopRoute exact path={routes.appointment} component={AppointmentPage}/>

          <ScrollToTopRoute exact path={routes.legal} component={LegalPage}/>

          <ScrollToTopRoute component={NotFoundPage}/>
        </Switch>
      </div>
    );
  }
}

export default RouterComponent;

import React, {Component} from 'react';
import routes from "../routes";
import {Link} from "react-router-dom";
import {Translate, withLocalize} from "react-localize-redux";
import footerTranslations from "../translations/footer";
import moment from "moment";


class FooterComponent extends Component {
  constructor(props, context) {
    super(props, context);

    this.props.addTranslation(footerTranslations);
  }

  render() {
    return (
      <footer className="footer-area">
        <div className="container">
          <div className="row">

            {this.footerWidget1()}
            {this.usefullLinks()}
            {this.footerContact()}
            <section className="footer-bottom-area">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="footer-bottom">
                      <div className="copyright-text pull-left">
                        <p>Copyrights © {moment().year()}&nbsp;
                          <Link to="/legal">Buffadini S.A.</Link>&nbsp;
                          <Translate id="footer.all_rights_reserved"/>.
                        </p>
                      </div>
                      <div className="footer-social-links pull-right">
                        <ul>
                          <li>
                            <a href="https://goo.gl/maps/92oCvA8Qs8fi7Rus6" target="_blank"
                               rel="noopener noreferrer">
                              <i className="fa fa-google" aria-hidden="true"/>
                            </a>
                          </li>
                          <li>
                            <a href="https://www.linkedin.com/company/buffadini-fils"
                               target="_blank"
                               rel="noopener noreferrer">
                              <i className="fa fa-linkedin" aria-hidden="true"/>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

          </div>
        </div>
      </footer>
    )
  }

  footerWidget1() {
    return (
      <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12">
        <div className="single-footer-widget mar-btm">
          <div className="footer-logo">
            <Link to={routes.home}>
              <img src="/images/logo.png" alt="Footer Logo"/>
            </Link>
          </div>
          <div className="our-info">
            <p><Translate id="footer.history"/></p>
            <div className="button">
              <Link className="" to="/history"><span className="flaticon-arrows-1"/><Translate id="global.more"/></Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  usefullLinks() {
    return (
      <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
        <div className="single-footer-widget mar-btm">
          <div className="title">
            <h3><Translate id="footer.labels"/></h3>
          </div>
          <div className="col-md-12">
            <img className="brand"
                 src="/images/logos/energie_fir_d_zukunft.jpg"
                 alt="Energie fir d'Zukunft"/>
            <img className="brand"
                 src="/images/logos/chambre_des_metiers_luxembourg.png"
                 alt="Chambre des Métiers"/>
            <img className="brand"
                 src="/images/logos/entreprise_formatrice.jpeg"
                 alt="Chambre des Métiers"/>
          </div>

          <div className="col-md-6">
            <img className="brand"
                 src="/images/logos/hands_up.png"
                 alt="Hands Up"/>
          </div>
          <div className="col-md-6">
            <img className="brand"
                 src="/images/logos/passiv_haus.jpg"
                 alt="Zertifizierter Passivhaus Handwerker"/>
          </div>
        </div>
      </div>
    )
  }

  footerContact() {
    return (
      <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <div className="single-footer-widget martop">
          <div className="title">
            <h3><Translate id="footer.contact"/></h3>
          </div>
          <ul className="footer-contact-info">
            <li>
              <div className="icon-holder">
                <span className="flaticon-pin map-marker"/>
              </div>
              <div className="text-holder">
                <h5>
                  <span><Translate id="global.address"/>:</span><br/>
                  <a href="https://maps.app.goo.gl/egXoXDhMi1dYE3fy6"
                     target="_blank"
                     rel="noopener noreferrer"> 2, rue Drosbach<br/>L-3372 Leudelange</a></h5>
              </div>
            </li>
            <li>
              <div className="icon-holder">
                <span className="flaticon-technology"/>
              </div>
              <div className="text-holder">
                <h5>
                  <span><Translate id="global.phone"/>:</span><br/>
                  <a href="tel:+3524032421">(+352) 40 32 42 - 1</a>
                </h5>
              </div>
            </li>
            <li>
              <div className="icon-holder">
                <span className="flaticon-interface"/>
              </div>
              <div className="text-holder">
                <h5>
                  <span><Translate id="global.email"/>:</span><br/>
                  <a href="mailto:info@buffadini.lu">info@buffadini.lu</a>
                </h5>
              </div>
            </li>
            <li>
              <div className="icon-holder">
                <span className="flaticon-clock"/>
              </div>
              <div className="text-holder">
                <h5><span><Translate id="footer.opening_hours"/>:</span><br/><Translate
                  id="global.monday_to_friday"/><br/>08:00 - 17:00</h5>
              </div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default withLocalize(FooterComponent);

import React, {Component} from 'react';
import {Link} from "react-router-dom";
import routes from "../routes";
import {Translate, withLocalize} from "react-localize-redux";
import jobsTranslations from "../translations/jobs.json";
import {Markdown} from "../components/Markdown";
import {getJobsByLang} from '../components/joboffers'

class JobsPage extends Component {

  constructor(props: P, context: any) {
    super(props, context);

    this.props.addTranslation(jobsTranslations);
  }

  render() {

    return (
      <div>

        <section className="breadcrumb-area">
          <div className="container text-center">
            <h1><Translate id="jobs.jobs"/></h1>
          </div>
        </section>

        <section className="breadcrumb-botton-area">
          <div className="container">
            <div className="left">
              <ul>
                <li><Link to={routes.home}><Translate id="navigation.homepage"/></Link></li>
                <li><i className="fa fa-caret-right" aria-hidden="true"/></li>
                <li className="active"><Translate id="jobs.jobs"/></li>
              </ul>
            </div>
          </div>
        </section>

        <section className="blog-area blog-single-area">
          <div className="container">

            <div className="row">
              <div className="col-md-12">
                <h4>Créée en 1989, notre société emploie actuellement 60 salarié(e)s, nos domaines d’activités sont le HVAC, l’électricité et les énergies renouvelables.
                  Dans le cadre de l’expansion de nos activités, nous recherchons les profils suivants :</h4>
              </div>
            </div>

            <div className="row">
              {getJobsByLang(this.props.activeLanguage).map(job => (
                <div className="col-md-12" key={job.id}>
                  <div className="single-blog-post" style={{marginBottom: 0, paddingBottom: "10px"}}>
                    <div className="text-holder">
                      <h3>{job.title}</h3>
                      <Markdown source={job.text}/>
                    </div>
                  </div>

                </div>
              ))}
            </div>

            <div className="row">
              <div className="col-md-12" style={{textAlign: "center", marginTop: "20px"}}>
                <h4>En cas d’intérêt, veuillez nous faire parvenir votre candidature comprenant votre CV et votre lettre de motivation à l’adresse suivante :</h4>
                <h4>Buffadini S.A., Service Ressources Humaines,<br />2 Rue Drosbach | L-3372 Leudelange<br />
                  ou par e-mail: <a href="mailto:jobs@buffadini.lu">jobs@buffadini.lu</a></h4>
                <hr />
              </div>
            </div>


          </div>
        </section>
      </div>
    )
  }
}

export default withLocalize(JobsPage);

import React from 'react';

export default function SingleServiceComponent({title, short, slug}) {

  let url = '/services/' + slug;

  return (
    <div className="col-md-4 col-sm-12 col-xs-12">
      <div className="single-item hvr-float-shadow">
        <div className="icon-holder">
          <a href={url}>
            <div className="icon-box">
              <div className="icon">
                <div className="icon-bg">
                  <span className="flaticon-air-conditioner-1"/>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="text-holder">
          <h3><a href={url}>{title}</a></h3>
          <p>{short}</p>
          <a href={url}>Read More<i
            className="fa fa-angle-right" aria-hidden="true"/></a>
        </div>
      </div>
    </div>
  );
}

import React, {Component} from 'react';

import Slider from "react-slick";


class CustomSlide extends Component {
  render() {
    const {imageName, ...props} = this.props;
    return (
      <div {...props} style={{
        maxHeight: "50vh",
      }}>
        <img src={"/images/slides/" + imageName} style={{
          width: '100%',
          minHeight: '100%',
        }} alt={imageName}/>
      </div>
    );
  }
}

export default class SliderComponent extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      accessibility: true,
      autoplay: true,
      pauseOnDotsHover: true,
      pauseOnHover: true,
    };
    return (
      <Slider {...settings}>
        <CustomSlide imageName="v4.jpg"/>
        <CustomSlide imageName="cables-close-up-computer-257736.jpg"/>
        <CustomSlide imageName="alternative-alternative-energy-clean-159397.jpg"/>
      </Slider>
    )
  };
}

import React, {Component} from 'react';
import PiwikReactRouter from 'piwik-react-router';
import {Router} from "react-router-dom";

import './App.scss';
import HeaderComponent from "./components/HeaderComponent";
import {history} from './_helpers/history'
import Navigation from "./components/Navigation";
import RouterComponent from "./modules/RouterComponent";
import FooterComponent from "./components/FooterComponent";

import globalTranslations from "./translations/global";
import {LocalizeProvider} from "react-localize-redux";
import store from "./_helpers/store";
import {Provider} from "react-redux";
import {config} from "./config/config";

class App extends Component {
  render() {
    const piwik = PiwikReactRouter({
      url: 'https://analytics.macknet.eu',
      siteId: 6,
      enableLinkTracking: true,
      trackErrors: true,
      ignoreInitialVisit: false,
      injectScript: true,
    });

    const languages = [
      {name: "Français", code: "fr"},
      {name: "Deutsch", code: "de"},
      {name: "English", code: "en"},
    ];
    let defaultLanguage = languages[0].code;

    const preferredLanguageCode = config.readLanguage();
    if (preferredLanguageCode) {
      defaultLanguage = preferredLanguageCode;
    }

    const initialize = {
      languages,
      translation: globalTranslations,
      options: {
        renderToStaticMarkup: false,
        defaultLanguage
      }
    };

    return (
      <Provider store={store}>
        <LocalizeProvider store={store} initialize={initialize}>
          <Router history={piwik.connectToHistory(history)}>
            <div>
              <HeaderComponent/>
              <Navigation/>

              <RouterComponent/>

              <FooterComponent/>
            </div>
          </Router>
        </LocalizeProvider>
      </Provider>
    );
  }
}


export default App;

export default {
  home: '/',
  history: '/history',
  jobs: '/jobs',
  contact: '/contact',

  appointment: '/appointment',

  services: '/services',
  servicesSingle: '/services/:slug',

  legal: '/legal',
};

import React, {Component} from 'react';
import {Link} from "react-router-dom";
import routes from "../../routes";
//import {getServicesByLang} from '../../components/services'
import {Translate, withLocalize} from "react-localize-redux";
import appointmentTranslations from '../../translations/appointment'

class AppointmentPage extends Component {


  constructor(props: P, context: any) {
    super(props, context);

    this.props.addTranslation(appointmentTranslations)
  }

  render() {

    //const services = getServicesByLang(this.props.activeLanguage);

    return (
      <div>
        <section className="breadcrumb-area">
          <div className="container text-center">
            <h1><Translate id="appointment.request_appointment"/></h1>
          </div>
        </section>

        <section className="breadcrumb-botton-area">
          <div className="container">
            <div className="left">
              <ul>
                <li><Link to={routes.home}><Translate id="navigation.homepage"/></Link></li>
                <li><i className="fa fa-caret-right" aria-hidden="true"/></li>
                <li className="active"><Translate id="appointment.request_appointment"/></li>
              </ul>
            </div>
          </div>
        </section>

        <section id="service-single-area" className="smartphone-repair-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12">
                <div className="service-single-content">

                  <div className="appointment-form">
                    <div className="title">
                      <h2><Translate id="appointment.request_appointment"/></h2>
                    </div>

                    <p>
                      <Translate id="appointment.maintenance"/>
                    </p>

                    {/*<form id="appointment-form" action="#">*/}
                    {/*  <div className="row">*/}
                    {/*    <div className="col-md-6">*/}
                    {/*      <input type="text" name="form_name" value="" placeholder="Your Name*" required=""/>*/}
                    {/*      <input type="email" name="form_email" value="" placeholder="Your Mail*" required=""/>*/}
                    {/*      <input type="text" name="form_phn" value="" placeholder="Phone Number*" required=""/>*/}
                    {/*    </div>*/}
                    {/*    <div className="col-md-6">*/}
                    {/*      <select className="selectmenu" name="form_service">*/}
                    {/*        <option>{this.props.translate("appointment.choose")}</option>*/}

                    {/*        {services.map(service => <option key={service.id}>{service.title}</option>)}*/}

                    {/*        <option>{this.props.translate("appointment.other")}</option>*/}
                    {/*      </select>*/}

                    {/*      <textarea name="form_message" placeholder="Your Message.." required=""/>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*  <div className="row">*/}
                    {/*    <div className="col-md-12">*/}
                    {/*      <button className="thm-btn bg-1" type="submit">Submit Now</button>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</form>*/}

                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

      </div>
    )
  }
}

export default withLocalize(AppointmentPage);

import React, {Component} from 'react';
import SingleServiceComponent from "./SingleServiceComponent";
import {getServicesByLang} from '../../components/services'
import {Link} from "react-router-dom";
import routes from "../../routes";
import {Translate, withLocalize} from "react-localize-redux";
import servicesTranslations from "../../translations/services.json";

class ServicesPage extends Component {

  constructor(props) {
    super(props);

    const {slug} = props.match.params;

    this.state = {slug};

    this.props.addTranslation(servicesTranslations);
  }

  render() {
    const services = getServicesByLang(this.props.activeLanguage);

    return (
      <div>
        <section className="breadcrumb-area">
          <div className="container text-center">
            <h1><Translate id="services.our_services"/></h1>
          </div>
        </section>

        <section className="breadcrumb-botton-area">
          <div className="container">
            <div className="left">
              <ul>
                <li><Link to={routes.home}><Translate id="navigation.homepage"/></Link></li>
                <li><i className="fa fa-caret-right" aria-hidden="true"/></li>
                <li><Link to={routes.services}><Translate id="services.our_services"/></Link></li>
              </ul>
            </div>
          </div>
        </section>

        <section className="services-area">
          <div className="container">

            <div className="row">
              {services.map(
                e => <SingleServiceComponent key={e.id} delay={0.5 * e.id} {...e}/>
              )}

            </div>

          </div>
        </section>
      </div>

    )
  }
}

export default withLocalize(ServicesPage);

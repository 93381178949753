import React, {Component} from 'react'
import {Map, Marker, Popup, TileLayer} from 'react-leaflet';
import {Translate, withLocalize} from "react-localize-redux";
import mapsTranslations from '../../translations/maps'

class MapsComponent extends Component {


  constructor(props: P, context: any) {
    super(props, context);

    this.props.addTranslation(mapsTranslations);
  }

  render() {
    const position = [49.568155, 6.076290];

    return (
      <Map
        center={position}
        zoom={15}
        maxZoom={19}
        attributionControl={true}
        zoomControl={true}
        doubleClickZoom={true}
        scrollWheelZoom={false}
        dragging={true}
        animate={true}
        easeLinearity={0.35}
        style={{height: '500px', width: '100%'}}>
        <TileLayer
          url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href="https://osm.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a> contributors'
        />
        <Marker position={position} ref={ref => {
          if (ref) {
            ref.leafletElement.openPopup()
          }
        }}>
          <Popup>
            <strong>Buffadini S.A.</strong><br/>
            2, Rue Drosbach<br/>
            L-3372 Leudelange<br/>
            <a
              href="https://maps.app.goo.gl/egXoXDhMi1dYE3fy6"
              target="_blank"
              rel="noopener noreferrer"><Translate id="maps.view_it_on_google_maps"/></a>
          </Popup>
        </Marker>
      </Map>
    )
  }
}

export default withLocalize(MapsComponent);

const jobs = {
  fr: [
    {
      id: 1,
      title: "MONTEUR - INSTALLATEUR QUALIFIÉ (H/F)",
      text: "**Votre mission :**\n" +
        "- Lecture et compréhension des plans de montage HVAC et sanitaires \n" +
        "- Montage des réseaux de tuyauterie, de gainage et accessoires (en métal ou synthétique) \n" +
        "- Maitrise des différentes techniques d’assemblage, comme la soudure, le sertissage, etc.\n" +
        "- Installation de machines de froid, de centrales de traitement d’air, de chaufferies, sanitaires, etc.\n" +
        "\n" +
        "**Profil recherché :**\n" +
        "- De formation chauffage – sanitaire - ventilation - climatisation\n" +
        "- Plus de 10 ans d’expérience dans le domaine \n" +
        "- Autonome\n" +
        "- Polyvalent\n" +
        "- Flexible",
    },
    {
      id: 2,
      title: "TECHNICIEN DE MAINTENANCE (H/F)",
      text: "**Votre mission :**\n" +
        "- Maintenance préventive et corrective des installations technique  \n" +
        "- Montage, transformation et remplacement d’installations techniques \n" +
        "- Lecture de plans, hydraulique et électrique\n" +
        "- De bonne compréhension dans le domaine de la régulation \n" +
        "- Rechercher et remédier aux défauts des installations techniques\n" +
        "\n" +
        "**Profil recherché :**\n" +
        "- De formation génie thermique, technique ou énergétique\n" +
        "- Considéré comme un atout tous certificats dans le domaine de la technique.",
    },
    {
      id: 3,
      title: "TECHNICIEN FRIGORISTE (H/F)",
      text: "**Votre mission :**\n" +
        "- Maintenance préventive et corrective des installations frigorifiques \n" +
        "- Maintenance préventive et corrective des installations de conditionnement d’air \n" +
        "- Montage, transformation et remplacement d’installations frigorifiques \n" +
        "- Lecture de plans, hydraulique et électrique\n" +
        "- De bonne compréhension dans le domaine de la régulation \n" +
        "- Rechercher et remédier aux défauts des installations techniques\n" +
        "\n" +
        "**Profil recherché :**\n" +
        "\n" +
        "- De formation génie thermique, climatique, technique ou énergétique\n" +
        "- Considéré comme un atout : Titulaire d’une attestation d’aptitude catégorie 1 pour la manipulation des fluides frigorigènes",
    },
    {
      id: 4,
      title: "TECHNICIEN CHAUFFAGISTE (H/F)",
      text: "**Votre mission :**\n" +
        "- Maintenance préventive et corrective des chaudière Gaz, fioul et bois \n" +
        "- Maintenance préventive et corrective des installations de conditionnement d’air \n" +
        "- Montage, transformation et remplacement d’installations de toutes puissances \n" +
        "- Lecture de plans, hydraulique et électrique\n" +
        "- De bonne compréhension dans le domaine de la régulation \n" +
        "- Rechercher et remédier aux défauts des installations techniques\n" +
        "\n" +
        "**Profil recherché :**\n" +
        "- De formation génie thermique, technique ou énergétique\n" +
        "- Considéré comme un atout : Titulaire de certificats gaz et fioul valides au Luxembourg",
    },
  ]
};

export function getJobsByLang(lang) {
  return jobs[lang.code] || jobs['fr'];
}

import React, {Component} from 'react';
import {Switch} from 'react-router-dom';
import SingleServicePage from "./SingleServicePage";
import ScrollToTopRoute from "../../components/ScrollToTopRoute";
import ServicesPage from "./ServicesPage";
import routes from "../../routes";

class ServicesRoutes extends Component {

  render() {
    return (
      <div>
        <Switch>
          <ScrollToTopRoute exact path={routes.services} component={ServicesPage}/>

          <ScrollToTopRoute path={routes.servicesSingle} component={SingleServicePage}/>
        </Switch>
      </div>
    );
  }
}

export default ServicesRoutes;

import React from 'react';
import ReactMarkdown from "react-markdown";
import breaks from "remark-breaks";

export const Markdown = (props) => {

  return (
    <ReactMarkdown {...props} plugins={[breaks]}/>
  )
};

import React from 'react'
import {Markdown} from "../../components/Markdown";

function Image({imgUrl, title}) {
  return (
    <div className="col-md-5 col-sm-12 col-xs-12">
      <div className="img-holder">
        <img src={imgUrl} alt={title}/>
      </div>
    </div>
  )
}

export default function ServiceContent({content, imgLeft}) {
  const {title, text, imgUrl} = content;

  return (
    <div>
      <div className="row top-content">

        {imgLeft && imgUrl && (
          <Image imgUrl={imgUrl} title={title}/>
        )}

        <div className="col-md-7 col-sm-12 col-xs-12">
          <div className="text-holder">
            {title && (
              <div className="title">
                <h2>{title}</h2>
              </div>
            )}
            <div className="text">
              <Markdown source={text}/>
            </div>
          </div>
        </div>

        {!imgLeft && imgUrl && (
          <Image imgUrl={imgUrl} title={title}/>
        )}

      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="border"/>
        </div>
      </div>

    </div>
  )
};

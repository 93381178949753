import React, {Component} from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import contactTranslations from "../../translations/contact";
import MapsComponent from "./MapsComponent";

class ContactPage extends Component {

  constructor(props: P, context: any) {
    super(props, context);

    this.props.addTranslation(contactTranslations);
  }

  render() {
    return (
      <div>
        <section className="contact-area">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="contact-info">
                  <div className="title">
                    <h2><Translate id="contact.contact"/></h2>
                  </div>
                  <div className="accordion-box">
                    <div className="accordion accordion-block">
                      <div className="accord-content collapsed">
                        <ul className="contact-info-list">
                          <li>
                            <div className="icon-holder">
                              <span className="flaticon-pin"/>
                            </div>
                            <div className="text-holder">
                              <h5>
                                <a href="https://maps.app.goo.gl/egXoXDhMi1dYE3fy6"
                                   target="_blank"
                                   rel="noopener noreferrer">
                                  <span>2, Rue Drosbach</span><br/>L-3372 Leudelange</a>
                              </h5>
                            </div>
                          </li>
                          <li>
                            <div className="icon-holder">
                              <span className="flaticon-technology"/>
                            </div>
                            <div className="text-holder">
                              <h5>
                                <span><Translate id="global.phone"/></span><br/>
                                <a href="tel:+3524032421">(+352) 40 32 42 - 1</a>
                              </h5>
                            </div>
                          </li>
                          <li>
                            <div className="icon-holder">
                              <span className="flaticon-interface"/>
                            </div>
                            <div className="text-holder">
                              <h5>
                                <span><Translate id="global.email"/></span><br/>
                                <a href="mailto:info@buffadini.lu">info@buffadini.lu</a>
                              </h5>
                            </div>
                          </li>
                          <li>
                            <div className="icon-holder">
                              <span className="flaticon-clock"/>
                            </div>
                            <div className="text-holder">
                              <h5>
                                <span><Translate id="global.monday_to_friday"/></span><br/>
                                08:00 - 17:00
                              </h5>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="contact-form">
                  {/*<div className="title">*/}
                  {/*<h2><Translate id="contact.send_us_a_message"/></h2>*/}
                  {/*</div>*/}

                  {/*<p>*/}
                  {/*<Translate id="contact.maintenance"/>*/}
                  {/*</p>*/}

                  {/*<form id="contact-form"*/}
                  {/*      name="contact_form"*/}
                  {/*      className="default-form"*/}
                  {/*      method="post"*/}
                  {/*      noValidate="novalidate">*/}
                  {/*  <div className="row">*/}
                  {/*    <div className="col-md-6">*/}
                  {/*      <input type="text"*/}
                  {/*             name="form_name"*/}
                  {/*             placeholder={this.props.translate("contact.name") + "*"}*/}
                  {/*             required={true}*/}
                  {/*             aria-required="true"/>*/}
                  {/*    </div>*/}
                  {/*    <div className="col-md-6">*/}
                  {/*      <input type="email"*/}
                  {/*             name="form_email"*/}
                  {/*             placeholder={this.props.translate("global.email") + "*"}*/}
                  {/*             required={true}*/}
                  {/*             aria-required="true"/>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*  <div className="row">*/}
                  {/*    <div className="col-md-6">*/}
                  {/*      <input type="text"*/}
                  {/*             name="form_phone"*/}
                  {/*             placeholder={this.props.translate("global.phone") + "*"}*/}
                  {/*             required={true}*/}
                  {/*             aria-required="true"/>*/}
                  {/*    </div>*/}
                  {/*    <div className="col-md-6">*/}
                  {/*      <input type="text"*/}
                  {/*             name="form_subject"*/}
                  {/*             placeholder={this.props.translate("contact.subject") + "*"}*/}
                  {/*             required={true}*/}
                  {/*             aria-required="true"/>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*  <div className="row">*/}
                  {/*    <div className="col-md-12">*/}
                  {/*  <textarea name="form_message"*/}
                  {/*            placeholder={this.props.translate("contact.your_message") + "*"}*/}
                  {/*            required={true}*/}
                  {/*            aria-required="true"/>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*  <div className="row">*/}
                  {/*    <div className="col-md-12">*/}
                  {/*      <button className="thm-btn bg-1" type="submit">*/}
                  {/*        <Translate id="contact.send_message"/>*/}
                  {/*      </button>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</form>*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        <MapsComponent/>
      </div>
    );
  }
}

export default withLocalize(ContactPage);

import React, {Component} from 'react';
import {Markdown} from "../components/Markdown";
import {Link} from "react-router-dom";
import routes from "../routes";
import {Translate, withLocalize} from "react-localize-redux";
import historyTranslations from "../translations/history.json";

class HistoryPage extends Component {

  constructor(props: P, context: any) {
    super(props, context);

    this.props.addTranslation(historyTranslations);
  }

  render() {
    const introText = this.introTextTranslations[this.props.activeLanguage.code] || this.introTextTranslations[this.props.defaultLanguage] || "";
    const timelineEntries = this.timelineEntriesTranslations[this.props.activeLanguage.code] || this.timelineEntriesTranslations[this.props.defaultLanguage] || [];

    return (
      <div>

        <section className="breadcrumb-area">
          <div className="container text-center">
            <h1><Translate id="history.history"/></h1>
          </div>
        </section>

        <section className="breadcrumb-botton-area">
          <div className="container">
            <div className="left">
              <ul>
                <li><Link to={routes.home}><Translate id="navigation.homepage"/></Link></li>
                <li><i className="fa fa-caret-right" aria-hidden="true"/></li>
                <li className="active"><Translate id="history.history"/></li>
              </ul>
            </div>
          </div>
        </section>

        <section className="blog-area blog-single-area">
          <div className="container">
            <div className="row">
              <div className="col-md-10">
                <div className="single-blog-post" style={{marginBottom: 0}}>
                  <div className="text-holder">
                    <Markdown source={introText}/>
                  </div>
                </div>

                <div className="timeline">

                  {timelineEntries.map(entry => (
                    <div key={entry.year} className="group">
                      <div className="box">
                        <div className="date">
                          <span className="day">{entry.year}</span>
                        </div>
                        <div className="post">
                          <div className="content">
                            <Markdown source={entry.text}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }

  timelineEntriesTranslations = {
    fr: [
      {
        year: 1989,
        text: 'Le Père et Jean-Paul Buffadini créent, dans la maison familiale, la société Buffadini P.& Fils.\n' +
          'Pierrot Buffadini est déjà chef monteur et dispose de nombreuses années d’expérience et d’une grande expertise de son métier; les meilleures conditions sont donc réunies pour se mettre à son compte.',
      },
      {
        year: 1992,
        text: 'En 3 ans seulement, la société s’est fait un nom et a besoin d\'un bureau plus grand. Le déménagement vers la route de Thionville à Bonnevoie débute alors.',
      },
      {
        year: 1996,
        text: 'Quatre ans après, ce bureau est à nouveau trop petit, on déménage donc à Hamm, dans la rue de Bitbourg pour travailler dans de meilleures conditions.',
      },
      {
        year: 1999,
        text: 'Suite à une nouvelle expansion, la société est une nouvelle fois confrontée à un manque de place. Nous décidons alors de déménager dans la rue de Mondorf située à Bonnevoie où nous resterons jusqu’en 2005.',
      },
      {
        year: 2005,
        text: 'Le plus grand déménagement de l\'histoire de la société vers le 65, rue des Bruyères à Howald.',
      },
      {
        year: 2009,
        text: '20ème anniversaire de la société.\n' +
          '20 ans d’expérience à votre service à tout moment.'
      },
      {
        year: 2014,
        text: '25e anniversaire',
      },
      {
        year: 2019,
        text: '30e anniversaire',
      }
    ],
    de: [
      {
        year: 1989,
        text: 'Le Père und Jean-Paul Buffadini gründen im heimischen Haus die Firma Buffadini P. & Fils. Pierrot Buffadini ist bereits Chefmonteur und kennt seinen Beruf durch langjährige Erfahrung und angeeignetes Fachwissen nur zu genau, Beste Voraussetzungen für eine Selbstständigkeit sind also gegeben.',
      },
      {
        year: 1992,
        text: 'Innerhalb von nur 3 Jahren hat sich die Firma einen Namen gemacht und ein grösseres Büro muss her, der Umzug nach Bonnevoie in die route de Thionville beginnt.',
      },
      {
        year: 1996,
        text: 'Nach vier Jahren wird auch dieses Büro zu klein, so dass in die rue de Bitbourg nach Hamm gewechselt wird, so dass bessere Arbeitsgegebenheiten entstehen können.',
      },
      {
        year: 1999,
        text: 'Durch weiteres Expandieren befindet sich die Firma ein weiteres Mal in räumlichen Notstand. Man beschliesst in die rue de Mondorf nach Bonnevoie zu ziehen, wo bis 2005 gearbeitet wird.',
      },
      {
        year: 2005,
        text: 'Der Grösste bis dahin da gewesene Umzug, unsere Firmenadresse 65, rue des Bruyères im Howald wird neu strukturiert.',
      },
      {
        year: 2009,
        text: 'Die Firma feiert ihr 20-jähriges Bestehen.\n' +
          '20 Jahre, auf die Sie sich jederzeit verlassen können.'
      },
      {
        year: 2014,
        text: '25-jähriges Bestehen',
      },
      {
        year: 2019,
        text: '30-jähriges Bestehen',
      }
    ],
    en: [
      {
        year: 1989,
        text: 'Pierrot and Jean Paul Buffadini set up the company P. Buffadini & Son in the family home.\n' +
          'Pierrot Buffadini had already become a head mechanic with many years experience and great expertise in his profession. This meant that the circumstances were just right for him.',
      },
      {
        year: 1992,
        text: 'In just three years the company made a name for itself and needed larger offices. This was when the move to the address, Route de Thionville at Bonnevoie, took place.',
      },
      {
        year: 1996,
        text: 'Four years later the Company’s offices had once again become too small. This meant a move to Hamm, Rue de Bitbourg, for the sake of better working conditions.',
      },
      {
        year: 1999,
        text: 'Once again, the company expanded. Again, there was not enough room, so we decided to move to Bonnevoie, Rue de Mondorf, where we stayed until 2005.',
      },
      {
        year: 2005,
        text: 'The biggest move in the company’s history was to 65, rue des Bruyères at Howald.',
      },
      {
        year: 2009,
        text: 'The 20th anniversary of the company.'
      },
      {
        year: 2014,
        text: '25th anniversary',
      },
      {
        year: 2019,
        text: '30th anniversary',
      }
    ]
  };
  introTextTranslations = {
    fr: "## L'entreprise\n" +
      "En 1989 Pierrot \"Père\" Buffadini crée la société. Ce qui a d’abord commencé comme une affaire familiale « Père et Fils » est vite devenu une PME qui compte actuellement 60 employés et employées dans les secteurs des installations sanitaires, de chauffage et électriques. Au début, le tandem Buffadini se déplaçait ensemble, principalement pour effectuer des dépannages. Puis l’activité de fabrication d’installations de chauffage se développa lentement mais sûrement. Des travaux d’installations sanitaires puis des travaux d'électricité vinrent ensuite se greffer. Aujourd'hui tout comme autrefois, le père et le fils effectuent des interventions et dans des \"cas difficiles\", ensemble. Nous allions l'expérience et la connaissance des nouvelles techniques pour satisfaire tous nos clients.",
    de: "## Die Firma\n" +
      "Bereits 1989 gründet Pierrot \"Père\" Buffadini die Firma. Was zuerst als \"Vater und Sohn\" - Geschäft beginnt, entwickelt sich schnell zu einem mittelständigen Unternehmen, das heute 60 Mitarbeiter und Mitarbeiterinnen in Bereich Sanitär, Heizung und Elektro beschäftigt. Das Buffadini-Gespann war anfangs noch gemeinsam unterwegs, hauptsächlich im Dépannagenbereich. Langsam, aber sicher wuchs auch die Nachfrage von kompletten Heizungsanlagen. Sanitärarbeiten kamen hinzu und später dann auch Elektro. Damals wie heute sind Vater und Sohn noch im Einsatz, in \"schwierigen Fällen\" auch gemeinsam. Erfahrung und neuste Fachkenntnisse vereinen sich, so dass unsere Kunden stets zufrieden sind.",
    en: "## The company\n" +
      "In 1989 Pierrot Buffadini, whom we see as our father, set up our company. It started as a family affair called Buffadini and Son. It quickly became a small and medium enterprise. Currently, it has sixty employees, both male and female, who deal with installations in the sanitary, heating and electrical sectors. At first, the Buffadini tandem always moved around together catering mainly for repairs. Subsequently, heating plant manufacture developed slowly but surely. After that sanitary installations and heating plant, together with electrical work, joined the main stream. Today, just as they always have, father and son tackle any specially difficult cases together. We combine experience and knowledge of new techniques that together offer customer satisfaction."
  }
}

export default withLocalize(HistoryPage);

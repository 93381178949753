import React from "react";
import {withLocalize} from "react-localize-redux";

const LanguageToggle = ({languages, activeLanguage, setActiveLanguage}) => (
  <ul className="selector">
    {languages.map(lang => (
      <button key={lang.code}
              className={lang.code === activeLanguage.code ? 'btn-primary' : null}
              onClick={() => setActiveLanguage(lang.code)}>
        {lang.name}
      </button>
    ))}
  </ul>
);

export default withLocalize(LanguageToggle);

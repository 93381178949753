import React from 'react';
import {Redirect, Route} from 'react-router-dom';

export const PrivateRoute = ({component: Component, ...rest}) => {

  return (
    <Route
      {...rest}
      render={props => (
        localStorage.getItem('jwt')
          ? <Component {...props} />
          :
          <div>

            <Redirect to={
              {
                pathname: '/login',
                search: `?redirect=${encodeURIComponent(props.location.pathname + props.location.search)}&private=true`,
                state: {
                  from: props.location
                }
              }
            }/>

          </div>
      )}/>
  )
}

import React, {Component} from 'react';
import {getServiceByLangAndSlug, getServicesByLang} from "../../components/services";
import routes from "../../routes";
import {Link} from "react-router-dom";
import {urlWithParams} from "../../_helpers/routes.helper";
import ServiceContent from "./ServiceContent";
import {Translate, withLocalize} from "react-localize-redux";
import _ from 'lodash';
import NotFoundPage from "../NotFoundPage";
import servicesTranslations from '../../translations/services';

class SingleServicePage extends Component {

  constructor(props) {
    super(props);

    const {slug} = props.match.params;

    this.state = {slug};

    this.props.addTranslation(servicesTranslations);
  }

  componentWillReceiveProps(nextProps, nextContext) {
    const {slug} = nextProps.match.params;

    const service = getServiceByLangAndSlug(nextProps.activeLanguage, slug);

    this.setState({
      slug,
      service: service
    })
  }

  render() {

    const service = getServiceByLangAndSlug(this.props.activeLanguage, this.state.slug);
    const services = getServicesByLang(this.props.activeLanguage);

    if (_.isNil(service)) {
      return (
        <NotFoundPage/>
      )
    }

    return (
      <div>
        <section className="breadcrumb-area">
          <div className="container text-center">
            <h1>{service.title}</h1>
          </div>
        </section>

        <section className="breadcrumb-botton-area">
          <div className="container">
            <div className="left">
              <ul>
                <li><Link to={routes.home}><Translate id="navigation.homepage"/></Link></li>
                <li><i className="fa fa-caret-right" aria-hidden="true"/></li>
                <li><Link to={routes.services}><Translate id="services.our_services"/></Link></li>
                <li><i className="fa fa-caret-right" aria-hidden="true"/></li>
                <li className="active">{service.title}</li>
              </ul>
            </div>
          </div>
        </section>

        <section id="service-single-area" className="smartphone-repair-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12 pull-right">
                <div className="service-single-content">

                  {service.content.map((content, index) => {
                    return (
                      <div key={content.title + content.imgUrl + content.text.substring(0, 8)}>
                        <ServiceContent content={content} imgLeft={index % 2 === 0}/>
                      </div>
                    )
                  })
                  }

                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-7 col-xs-12 pull-left">
                <div className="service-single-sidebar">
                  <div className="single-sidebar">
                    <ul className="service-lists">
                      <li className="allservice"><Link to={routes.services}><Translate id="services.all_services"/><i
                        className="fa fa-cog"
                        aria-hidden="true"/></Link>
                      </li>

                      {services.map(s => {
                        return <li key={s.slug} className={service.slug === s.slug ? 'active' : null}>
                          <Link to={urlWithParams(routes.servicesSingle, {slug: s.slug})}>{s.title}</Link>
                        </li>
                      })}
                    </ul>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

      </div>
    );
  }
}

export default withLocalize(SingleServicePage);

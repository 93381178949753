import React, {Component} from 'react';
import notFoundTranslations from "../translations/notFound";
import {Translate, withLocalize} from "react-localize-redux";
import {Link} from "react-router-dom";

class NotFoundPage extends Component {

  constructor(props, context) {
    super(props, context);

    this.props.addTranslation(notFoundTranslations);
  }

  render() {

    return (
      <section className="not-found-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="not-found-content text-center">
                <h1>404</h1>
                <h3><Translate id="notFound.page_could_not_be_found"/></h3>
                <p><Link to={'/'} style={{cursor: 'pointer'}}><Translate id="notFound.home_page"/></Link></p>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
  }
}

export default withLocalize(NotFoundPage);

import {applyMiddleware, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';
import rootReducer from '../reducers';
import ReduxPromise from 'redux-promise';


let store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware,
    ReduxPromise
  )
);

if (process.env.NODE_ENV !== "production") {

  store = createStore(
    rootReducer,
    applyMiddleware(
      thunkMiddleware,
      createLogger(),
      ReduxPromise
    )
  );

}

export default store;

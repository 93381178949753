import React, {Component} from "react";
import {PrivateRoute} from "./PrivateRoute";
import {Route, withRouter} from "react-router-dom";

export class STTRoute extends Component {

  componentDidUpdate(prevProps) {
    window.scrollTo(0, 0)
  }

  render() {

    if (this.props.private) {
      return <PrivateRoute {...this.props} />;
    }

    const {component: Component, ...rest} = this.props;

    return <Route {...rest} render={props => (<Component {...props} />)}/>;
  }
}

export default withRouter(STTRoute);

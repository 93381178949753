const services = {
  fr: [
    {
      id: 1,
      slug: "heating-systems",
      title: "Installation de chauffage",
      short: "Qu'il s'agisse de mazout, de gaz, de solaire / photovoltaïque, de chaudières à bois ou d'une pompe à chaleur, nous avons la bonne solution.",
      content: [{
        title: "Écologique et économique",
        text: "Grâce à notre coopération avec nos marques de chauffage préférées BUDERUS et VIESSMANN nous sommes en mesure de vous garantir une faible consommation d’énergie et une excellente qualité. Que vous choisissiez le mazout, le gaz, des systèmes solaires / photovoltaïques, une chaudière à bois ou une pompe à chaleur, avec nous vous pouvez être sûr d’obtenir une installation à la fois écologique et économique car nous vous proposerons la solution énergétique la mieux adaptée à vos besoins grâce à notre consultation sur place. Avec des prix de l’énergie toujours plus importants, vous serez vite récompensés. Étant une société indépendante et sans lien avec un fabricant en particulier, nous vous offrons un conseil professionnel et impartial.",
        imgUrl: null
      }],
    },
    {
      id: 2,
      slug: "radiator",
      title: "Radiateurs",
      short: "Les formes modernes et la géométrie intemporelle vous donnent une sensation agréable d'entrer dans l'appartement.",
      content: [{
        title: null,
        text: "Quelle sensation agréable de pénétrer dans une maison tout en appréciant le design de ses radiateurs.\n" +
          "\n" +
          "Grâce à des formes modernes et à une géométrie intemporelle, c’est désormais possible. Votre radiateur attirera alors tous les regards et certains modèles vaudront même la peine d’y revenir une seconde fois. Design et efficacité énergétique ne vont pas l’un sans l’autre, bien au contraire. Laissez-nous vous conseiller pour trouver le radiateur qui s'intégrera parfaitement dans votre intérieur.",
        imgUrl: null
      }],
    },
    {
      id: 3,
      slug: "bathroom",
      title: "Salle de bain",
      short: "Plans 3D, chauffage, électricité, carrelage, menuiserie, peinture, saunas, cabines infrarouges, bains à remous, spa, douches et bains turcs, solariums ... Nous avons tout pour votre salle de bain.",
      content: [
        {
          title: "Réalisation",
          text: "Réalisation de votre salle de bain complète en lien avec les domaines suivants:" +
            "\n" +
            "Plans 3D, chauffage, électricité, carrelage, menuiserie, peinture, saunas, cabines infrarouges, bains à remous, spas, douches et bains turcs ainsi que des solariums",
          imgUrl: "/images/services/bathroom/architecture-bathroom-bathtub-1454804.jpg"
        },
        {
          title: "Votre salle de bain",
          text: "Une sensation revigorante pour se réveiller le matin et savoir entrer dans une oasis de bien-être. La salle de bain est la première pièce dans laquelle nous entrons après nous être levés. Pendant longtemps, on lui a accordé peu d'importance. Nous y passons beaucoup de temps pour préparer de manière optimale la journée à venir. Avec nous, vous pouvez être sûr que vous ne rencontrerez aucune mauvaise surprise lors de la rénovation. Nous vous assurons une \"salle de bain main dans la main\" qui sera une expérience permanente magique de la rénovation à la finition.",
          imgUrl: "/images/services/bathroom/architecture-bathroom-contemporary-280209.jpg"
        }
      ],
    },
    {
      id: 4,
      slug: "electric",
      title: "Secteur électrique",
      short: "Qu'il s'agisse de rénovations ou de nouveaux bâtiments, nous livrons tout ce qui concerne l'électricité.",
      content: [
        {
          title: "Contrôle intelligent de la maison",
          text: "Notre département électrique nouvellement structuré vous propose des solutions individuelles pour toute votre maison. Qu'il s'agisse de rénovations ou de nouveaux bâtiments, nous livrons tout ce qui concerne l'électricité." +
            "\n" +
            "Ici, nous nous concentrons sur la commande domestique universelle et intelligente avec le système KNX à l'épreuve du temps. Nous nous sommes également spécialisés dans la technologie radio sans fil. Nos techniciens se feront un plaisir de vous conseiller sur toutes les questions relatives à l'éclairage, aux stores et volets électriques, au contrôle de la température et du climat dans vos quatre murs.",
          imgUrl: "/images/services/electric/cables-close-up-computer-257736.jpg"
        },
        {
          title: "Sécurité",
          text: "À notre époque, la question de la sécurité devient également de plus en plus importante. Pour une sensation de sécurité dans vos quatre murs, nous vous proposons différentes solutions de système d'alarme. Les détecteurs de fumée font également partie de la protection. L'ange gardien invisible est commuté de manière à ce que les détecteurs de fumée n'attirent pas l'attention et offrent toujours une protection maximale.",
          imgUrl: "/images/services/electric/door-handle-key-279810.jpg"
        },
        {
          title: "Systèmes sonores",
          text: "Les systèmes de communication et de sonorisation ne sont pas non plus négligés. En matière de musique, nous proposons des installations murales qui promettent la perfection en matière de son et de design. Nous connectons également tous les composants multimédias, la technologie réseau et plus encore.",
          imgUrl: null
        }],
    },
    {
      id: 5,
      slug: "fireplace",
      title: "Chauffage et cheminée",
      short: "Nous réalisons votre installation de chauffage et réparation de la cheminée.",
      content: [
        {
          title: null,
          text: "Nous réalisons votre installation de chauffage et réparation de la cheminée. Nous garantissons que nous sommes toujours à jour avec les dernières technologies. Nous analysons vos systèmes de chauffage existants et trouvons la solution la plus économique et la plus adaptée pour vous. Nous vous protégeons également des problèmes de condensation. Nous vous donnons de précieux conseils et sommes heureux de vous conseiller, car rien n'est plus relaxant que d'écouter le crépitement d'un feu brûlant.",
          imgUrl: null
        }
      ],
    },
    {
      id: 6,
      slug: "wellness",
      title: "Wellness",
      short: "Afin que vous puissiez vous détendre après une journée de travail, nous vous proposons la solution complète pour les piscines, saunas et bains à remous ainsi que les baignoires et douches avec fonction de massage.",
      content: [
        {
          title: "Oasis de bien-être",
          text: "Afin que vous puissiez vous détendre après une journée de travail, nous vous proposons la solution complète pour les piscines, saunas et bains à remous ainsi que les baignoires et douches avec fonction de massage." +
            "\n" +
            "Laissez-nous vous conseiller dans le domaine du spa et du bien-être.",
          imgUrl: "/images/services/wellness/architektur-brucke-entspannung-268092.jpg",
        }
      ],
    },
    {
      id: 7,
      slug: "renewable-energy",
      title: "Énergie renouvelable et environnement",
      short: "Installations solaires pour systèmes de chauffage écologiques avec eau potable. Des systèmes solaires thermiques basés sur des tubes et des collecteurs plats pour la préparation d'eau chaude et le support de chauffage aux modules photovoltaïques pour la production d'électricité, nous vous proposons un ensemble complet.",
      content: [
        {
          title: "Installations solaires",
          text: "Installations solaires pour systèmes de chauffage écologiques avec eau potable. Des systèmes solaires thermiques basés sur des tubes et des collecteurs plats pour la préparation d'eau chaude et le support de chauffage aux modules photovoltaïques pour la production d'électricité, nous vous proposons un ensemble complet.",
          imgUrl: "/images/services/renewable-energy/alternative-alternative-energy-clean-159397.jpg",
        },
        {
          title: "Pompes à chaleur basées sur les énergies renouvelables",
          text: "Avec nos différents partenaires, nous pouvons également effectuer le carottage pour vous. Parce que rien n'est plus efficace que d'utiliser la chaleur de la nature. Ici aussi, nous avons une large gamme de variantes pour vous: des appareils compacts particulièrement adaptés aux maisons passives aux solutions en cascade d'une puissance de plusieurs centaines de kilowatts, nous vous basculerons vers la méthode qui vous convient le mieux.",
          imgUrl: "/images/services/renewable-energy/v1.jpg",
        },
        {
          title: "Pellets et chauffage au bois",
          text: "Afin de garantir un approvisionnement en chaleur des maisons aux systèmes complexes, Buffadini vous propose des solutions complètes pour votre approvisionnement en chaleur. En tant que matière première renouvelable, le bois est une alternative écologique au pétrole ou au gaz. Vous pouvez nous poser des questions sur les mesures gouvernementales pour l'utilisation des énergies renouvelables et des informations sur les possibilités de financement. Nos techniciens sont toujours à jour avec les dernières opportunités de financement.",
          imgUrl: null,
        },
        {
          title: "Climatisation",
          text: "Un système de climatisation a pour tâche de climatiser une pièce à l'aide de l'air soufflé et du conduit d'air. La classification est basée sur les fonctions thermodynamiques régulées pour l'air soufflé. Buffadini développe pour vous des concepts de ventilation simples, ainsi que des systèmes de ventilation avec fonctions de chauffage ou de chauffage de l'air et des systèmes de climatisation complets.",
          imgUrl: null,
        },
        {
          title: "Interventions avec nos caméras",
          text: "• Nous vous offrons également la possibilité de générer des rapports grâce à nos différents types de caméras." +
            "• Intervention par caméra thermographique (recherche de fuites dans les tuyaux de chauffage)\n" +
            "• Intervention par caméra à tube (découverte de tout blocage)",
          imgUrl: null,
        }
      ],
    },
    {
      id: 8,
      slug: "swimming-pool",
      title: "Votre piscine",
      short: "Avoir sa propre piscine est un rêve pour beaucoup. Nous le réalisons avec vous.",
      content: [
        {
          title: null,
          text: "Avoir sa propre piscine est un rêve pour beaucoup. Nous le réalisons avec vous. Nous vous accompagnons dans votre projet de construction de la planification 3D à la réalisation. Trouvez la forme, la taille, les carreaux et les conditions générales avec nous. Nous apportons les connaissances techniques, vous vos idées." +
            "\n" +
            "Et au-delà, nous sommes là pour vous avec notre service de maintenance. Nous nous chargeons du ménage hebdomadaire pour vous. Avec nous, vous avez la possibilité de réserver directement la révision et la maintenance. Des spécialistes expérimentés s'occupent de vos préoccupations afin que vous puissiez vous détendre chez vous sans soucis. Nous nous chargeons également de l'entretien pendant vos vacances afin que vous puissiez vous détendre sans souci.",
          imgUrl: "/images/services/swimming-pool/architektur-baume-dach-1488267.jpg"
        }
      ],
    },
    {
      id: 9,
      slug: "tiling",
      title: "Carrelage",
      short: "Avec créativité et compétence, notre entreprise réussit à créer non seulement une ambiance hygiénique mais également équilibrée grâce à des carreaux individuels.",
      content: [
        {
          title: null,
          text: "Avec créativité et compétence, notre entreprise réussit à créer non seulement une ambiance hygiénique mais aussi équilibrée grâce à des carreaux individuels. Carrelages insolites, couleurs tendances ou formats stylés: nous vous proposons notre large gamme" +
            "\n" +
            "- carrelage brillant\n" +
            "- carrelage en grès cérame\n" +
            "- carrelage en pierre naturelle, intérieur et extérieur\n" +
            "- pose de mosaïques\n" +
            "\n" +
            "Vous êtes invités à prendre rendez-vous pour une consultation où nous pouvons trouver ensemble le carrelage qui convient à votre style personnel.",
          imgUrl: "/images/services/tiling/blumenvase-boden-couch-280232.jpg"

        }
      ],
    },
    {
      id: 10,
      slug: "suitable-for-disabled",
      title: "La salle de bain sans barrières",
      short: "Surtout dans la salle de bain, les gens veulent la paix et l'intimité. Afin de garantir que cet espace offre également tout le confort aux personnes âgées et handicapées, il y a quelques éléments à prendre en compte lors de la planification.",
      content: [
        {
          title: null,
          text: "Dans la salle de bain en particulier, les gens veulent la paix et l'intimité. Afin de garantir que cet espace offre également tout le confort aux personnes âgées et handicapées, il y a quelques éléments à prendre en compte lors de la planification. Cependant, vous ne devez pas seulement penser aux besoins individuels nécessaires. Une salle de bain accessible aux handicapés n'a pas à donner l'impression d'être à l'hôpital ou dans un centre de réadaptation. Même dans une salle de bain accessible aux personnes à mobilité réduite, vous pouvez vous sentir bien et profiter de tout le confort. Cependant, l'objectif principal est la convivialité. À quoi sert la plus belle salle de bain si le fauteuil roulant ne passe pas par la porte ou ne peut pas être tourné dans la salle de bain?" +
            "\n" +
            "Dans notre programme 3D, nous pouvons afficher à l'avance votre salle de bain accessible aux personnes handicapées sur le moniteur et simuler diverses variantes et options. Ensemble, nous trouverons la solution optimale qui allie fonctionnalité et design.",
          imgUrl: "/images/services/suitable-for-disabled/suitable-for-disabled.jpg"
        }
      ],
    }
  ],
  de: [
    {
      id: 1,
      slug: "heating-systems",
      title: "Heizungsanlagen",
      short: "Egal ob Heizöl, Gas, Solar / Photovoltaik, Holzkessel oder eine Wärmepumpe, wir haben die passende Lösung.",
      content: [{
        title: "Ökologisch & ökonomisch",
        text: "In Kombination mit unseren bevorzugten Heizungsmarken BUDERUS und VIESSMANN wird Ihnen ein geringer Rohstoffverbrauch sowie erstklassige Qualität gewährleistet. Ob Sie sich für Heizöl, Gas, Solar / Photovoltaik, Holzkessel oder eine Wärmepumpe entscheiden, mit uns können Sie sicher sein, dass Sie eine ökologisch sowie ökonomische Anlage bekommen werden, denn bei uns finden Sie in jedem Fall die beste Energielösung durch unsere Energieberatung vor Ort. Und dies rechnet sich auf für Ihre Zukunft, bei steigenden Rohstoffpreisen. Da wir ein unabhängiger Betrieb sind, der nicht an einen Hersteller gebunden ist, beraten wir Sie professionnel und ihne Einschränkungen.",
        imgUrl: null
      }],
    },
    {
      id: 2,
      slug: "radiator",
      title: "Heizkörper",
      short: "Moderne Formen und zeitlose Geometrie bereitet Ihnen ein angenehmes Gefühl die Wohnung zu betreten.",
      content: [{
        title: null,
        text: "Ein angenehmes Gefühl die Wohnung zu betreten und sich auch am Design seiner Heizkörper erfreuen zu können. Moderne Formen und zeitlose Geometrie machen es möglich. So wird auch Ihr Heizkörper zum Hingucker, bei manchen Modellen lohnt es sich sogar zweimal zu schauen. Skulptur und Energieträger liegen nicht weit voneinander entfernt. Lassen Sie sich von uns beraten und finden Sie den Heizkörper, der sich optimal in Ihre Wohnung integriert.",
        imgUrl: null
      }],
    },
    {
      id: 3,
      slug: "bathroom",
      title: "Badezimmer",
      short: "3D Pläne, Heizung, Elektro, Fliesen, Schreinerarbeiten, Farbanstrichen, Saunas, Infrarotkabinen, Whirlpools, Spa, Duschen und türkischen Bädern, Solarien... Wir haben alles rund um Ihr Badezimmer.",
      content: [
        {
          title: "Realisation",
          text: "Realisation von Ihrem kompletten Badezimmer in Verbindung mit folgenden Bereichen:" +
            "\n" +
            "3D Pläne, Heizung, Elektro, Fliesen, Schreinerarbeiten, Farbanstrichen, Saunas, Infrarotkabinen, Whirlpools, Spas, Duschen und türkischen Bädern sowie Solarien",
          imgUrl: "/images/services/bathroom/architecture-bathroom-bathtub-1454804.jpg"
        },
        {
          title: "Ihr Badezimmer",
          text: "Ein belebendes Gefühl morgens aufzuwachen und zu wissen, in eine Oase des Wohlfühlens zu treten. Das Badezimmer ist der erste Raum am Tag, den wir nach dem Aufstehen betreten. Lange Zeit wurde ihm wenig Bedeutung zugesagt. Dabei verbringen wir viel Zeit darin, um uns auf den bevorstehenden Tag opimal vorzubereiten. Mit uns können Sie sicher gehen auch während der Renovierung keine bösen Überraschungen zu erleben. Wir versicheren Ihnen ein \"Hand in Hand Badezimmer\", das von der Renovierung bis zur Fertigstellung ein zauberhaftes Dauererlebnis sein wird.",
          imgUrl: "/images/services/bathroom/architecture-bathroom-contemporary-280209.jpg"
        }
      ],
    },
    {
      id: 4,
      slug: "electric",
      title: "Elektrobereich",
      short: "Ob Renovierungen oder Neubauten, wir liefern Ihnen alles ums Thema Elektrik.",
      content: [
        {
          title: "Intelligente Haussteuerung",
          text: "Unser neu strukturierter Elektrobereich bietet Ihnen individuelle Lösungen für Ihr komplettes Haus. Ob Renovierungen oder Neubauten, wir liefern Ihnen alles ums Thema Elektrik." +
            "\n" +
            "Hier legen wir unsere Schwerpunkte auf die universelle und intelligente Haussteuerung mit dem zukunftssicheren KNX-System. Auch auf kabellose Funk-Technik haben wir uns spezialisiert. Unsere Techniker beraten Sie gerne in allen Fragen zum Thema Beleuchtung, Jalousien und elektrische Rollläden, Steuerung von Temperatur und Klima in Ihren vier Wänden.",
          imgUrl: "/images/services/electric/cables-close-up-computer-257736.jpg"
        },
        {
          title: "Sicherheit",
          text: "In unserer heutigen Zeit ist auch das Thema Sicherheit immer wichtiger. Für ein sicheres Gefühl in den eigenen vier Wänden bieten wir Ihnen verschiedene Lösungen von Alarmanlagen an. Zum Schutz zählen auch Rauchmelder.  Der unsichtbare Schutzengel wird so geschaltet, dass die Rauchmelder nicht auffallen und trotzdem höchsten Schutz bieten.",
          imgUrl: "/images/services/electric/door-handle-key-279810.jpg"
        },
        {
          title: "Soundsysteme",
          text: "Auch die Faktoren Kommunikation und Soundsysteme kommen nicht zu kurz. In Sachen Musik bieten wir Ihnen Wandeinbauen, die Perfektion in Klang und Design versprechen. Des Weiteren verbinden wir alle Multimediakomponenten, Netzwerktechnik und mehr.",
          imgUrl: null
        }],
    },
    {
      id: 5,
      slug: "fireplace",
      title: "Heizung und Kamin",
      short: "Wir realisieren Ihre Heizungsinstallation und Instandsetzung des Kamins.",
      content: [{
        title: null,
        text: "Wir realisieren Ihre Heizungsinstallation und Instandsetzung des Kamins. Dabei garantieren wir Ihnen, dass wir immer auf dem neusten technischen Stand sind. Wir analysieren Ihre bestehenden Heizungsanlagen und finden die wirtschaftlichste und geeigneteste Lösung für Sie. Ausserdem bewahren wir Sie vor Kondensationsproblemen. Wir geben wertvolle Tipps und beraten Sie gerne, denn nichts ist entspannender, dem Knistern eines brennenden Feuers zu zuhören.",
        imgUrl: null
      }],
    },
    {
      id: 6,
      slug: "wellness",
      title: "Wellness",
      short: "Damit Sie sich nach einem Arbeitstag entspannen können, bieten wir Ihnen die Komplettlösung für Schwimmbad, Saunen und Whirlpools sowie Wannen und Duschen mit Massagefunktion an.",
      content: [
        {
          title: "Wohlfühloase",
          text: "Damit Sie sich nach einem Arbeitstag entspannen können, bieten wir Ihnen die Komplettlösung für Schwimmbad, Saunen und Whirlpools sowie Wannen und Duschen mit Massagefunktion an." +
            "\n" +
            "Lassen Sie sich von uns im Bereich Spa und Wellness beraten.",
          imgUrl: "/images/services/wellness/architektur-brucke-entspannung-268092.jpg",
        }
      ],
    },
    {
      id: 7,
      slug: "renewable-energy",
      title: "Erneuerbare Energie und Umwelt",
      short: "Solarinstallationen für ökologische Heizanlagen mit Nutzwasser. Von solarthermischen Anlagen basierend auf Röhren und Flachkollektoren zur Warmwasseraufbereitung und Heizungsunterstützung bis hin zu Photvoltaikmodeulen zur Stromerzeugung erhalten Sie von uns das Rund-Um-Paket.",
      content: [
        {
          title: "Solarinstallationen",
          text: "Solarinstallationen für ökologische Heizanlagen mit Nutzwasser. Von solarthermischen Anlagen basierend auf Röhren und Flachkollektoren zur Warmwasseraufbereitung und Heizungsunterstützung bis hin zu Photvoltaikmodeulen zur Stromerzeugung erhalten Sie von uns das Rund-Um-Paket.",
          imgUrl: "/images/services/renewable-energy/alternative-alternative-energy-clean-159397.jpg",
        },
        {
          title: "Wärmepumpen basierend auf erneuerbaren Energien",
          text: "Mit unseren verschiedenen Partnern übernehmen wir auch die Kernbohrung für Sie. Denn nichts ist effektiver, als die Wärme aus der Natur zu nutzen. Auch hier haben wire in breites Spektrum an Varianten für Sie: Von Kompaktgeräten, die sich besonders für Passivhäuser eignen, bis hin zu Kaskadenlösungen mit mehreren Hundert Kilowatt Leistung, werden Sie von uns auf die für Sie beste Methode umsteigen.",
          imgUrl: null,
        },
        {
          title: "Pellets und Holzheizungen",
          text: "Um eine Wärmeversorgung von Häusern bi shin zu komplexen Anlagen zu gewährleisten, bietet Buffadini Ihnen Komplettlösungen für Ihre Wärmeversorgung. Holz ist als nachwachsender Rohstoff eine umweltschonende Alternative zu Öl oder Gas. Staatliche Maßnahmen zur Nutzung erneuerbarer Energien und Informationen zu den Fördermöglichkeiten können Sie gerne bei uns erfragen. Unsere Techniker sind immer auf dem neusten Stand der aktuellen Fördermöglichkeiten.",
          imgUrl: null,
        },
        {
          title: "Klimatisation",
          text: "Eine Klimaanlage hat die Aufgabe mit Hilfe der Zuluft und der Luftführung einen Raum zu konditionieren. Die Einteilung erfolgt nach den geregelten thermodynamischen Funktionen für die Zuluft. Buffadini entwickelt für Sie einfache Lüftungskonzepte, sowie Lüftungsanlagen mit Heizfunktion bzw. Luftheizungsfunktion und Vollklimaanlagen.",
          imgUrl: null,
        },
        {
          title: "INTERVENTIONEN MIT UNSEREN KAMERAS",
          text: "• Wir bieten Ihnen auch die Möglichkeit durch unsere verschiedenen Arten von Kameras Berichte zu erstellen." +
            "• Intervention durch Thermographiekamera (Herausfinden von Leckagen an Heizungsrohren)\n" +
            "• Intervention durch Schlauchkamera (Herausfinden von entstandenen oder entstehenden Verstopfungen)",
          imgUrl: null,
        }
      ],
    },
    {
      id: 8,
      slug: "swimming-pool",
      title: "Ihr Schwimmbad",
      short: "Ein eigenes Schwimmbad zu haben, für viele ein Traum. Wir realisieren ihn mit Ihnen.",
      content: [
        {
          title: null,
          text: "Ein eigenes Schwimmbad zu haben, für viele ein Traum. Wir realisieren ihn mit Ihnen. Von der 3D-Planung bis zur Fertigstellung betreuen wir Sie bei Ihrem Bauvorhaben. Suchen Sie mit uns gemeinsam Form, Grösse, Fliesen und die Rahmenbedingungen aus. Die technischen Kenntnisse bringen wir, Sie ihre Vorstellungen." +
            "\n" +
            "Und auch darüber hinaus sind wir mit unserem Wartungsservice für Sie da. Die wöchentliche Reinigung übernehmen wir für Sie. Bei uns haben Sie die Möglichkeit die Revision und die Instandhaltung direkt mitzubuchen. Erfahrenes Fachpersonal kümmert sich um Ihre Anliegen, so dass Sie sich zu Hause unbesorgt erholen können. Auch während Ihres Urlaubaufenthaltes übernehmen wir die Wartung, so dass Sie unbesorgt relaxen können.",
          imgUrl: "/images/services/swimming-pool/architektur-baume-dach-1488267.jpg"
        }
      ],
    },
    {
      id: 9,
      slug: "tiling",
      title: "Fliesen",
      short: "Mit Kreativität und Können gelingt es unserem Unternehmen durch individuelle Fliesen nicht nur einen hygienischen sondern auch ein ausgewogenes Ambiente zu schaffen.",
      content: [
        {
          title: null,
          text: "Mit Kreativität und Können gelingt es unserem Unternehmen durch individuelle Fliesen nicht nur einen hygienischen sondern auch ein ausgewogenes Ambiente zu schaffen. Ausgefallene Fliesen, trendige Farben oder stylische Formate: in unserem breit gefächerten Sortiment bieten wir Ihnen" +
            "\n" +
            "- Hochglanzfliesen\n" +
            "- Steinfeinzeug\n" +
            "- Natursteine für den Außen- und Innenbereich\n" +
            "- Mosaikverlegung\n" +
            "\n" +
            "Gerne vereinbaren Sie einen Beratungstermin, bei dem wir zusammen die Fliese finden, die zu Ihrer persönlichen Stilrichtung passt.",
          imgUrl: "/images/services/tiling/blumenvase-boden-couch-280232.jpg"

        }
      ],
    },
    {
      id: 10,
      slug: "suitable-for-disabled",
      title: "Das barrierefreie Bad",
      short: "Vor allem im Bad will der Mensch sein Ruhe und Privatsphäre haben. Damit dieser Bereich auch älteren Menschen und Menschen mit Handicap allen Komfort bietet, ist bei der Planung einiges zu beachten.",
      content: [
        {
          title: null,
          text: "Vor allem im Bad will der Mensch seine Ruhe und Privatsphäre haben. Damit dieser Bereich auch älteren Menschen und Menschen mit Handicap allen Komfort bietet, ist bei der Planung einiges zu beachten. Dabei sollte jedoch nicht nur an die notwendigen individuellen Bedürfnisse gedacht werden. Ein behindertengerechtes Badezimmer muss nicht das Gefühl von Krankenhaus oder Reha-Zentrum aufkommen lassen. Auch in einem barrierefreien Bad kann man sich wohlfühlen und allen Komfort genießen. Primär steht jedoch die Nutzbarkeit im Vordergrund. Was nutzt das schönste Bad, wenn der Rollstuhl nicht durch die Tür passt oder sich im Bad nicht wenden lässt?" +
            "\n" +
            "In unserem 3D Programm können wir Ihr behindertengerechtes Bad im Vorfeld am Monitor darstellen  und verschiedene Varianten und Möglichkeiten simulieren. Gemeinsam werden wir die optimale Lösung die Funktionalität und Design verbindet, finden.",
          imgUrl: "/images/services/suitable-for-disabled/suitable-for-disabled.jpg"
        }
      ],
    }
  ],
  en: [
    {
      id: 1,
      slug: "heating-systems",
      title: "Heating systems",
      short: "Regardless of whether heating oil, gas, solar / photovoltaics, wood boilers or a heat pump, we have the right solution.",
      content: [{
        title: "Ecological & economical",
        text: "In combination with our preferred heating brands BUDERUS and VIESSMANN, you are guaranteed low raw material consumption and first-class quality. Whether you opt for heating oil, gas, solar / photovoltaics, wood boilers or a heat pump, with us you can be sure that you will get an ecological and economical system, because with us you will always find the best energy solution through our energy advice Place. And this pays off for your future, with rising raw material prices. Since we are an independent company that is not tied to a manufacturer, we advise you professionally and without restrictions.",
        imgUrl: null
      }],
    },
    {
      id: 2,
      slug: "radiator",
      title: "Radiator",
      short: "Modern shapes and timeless geometry give you a pleasant feeling to enter the apartment.",
      content: [{
        title: null,
        text: "A pleasant feeling to enter the apartment and to be able to enjoy the design of your radiators. Modern shapes and timeless geometry make it possible. Your radiator will also be an eye-catcher, with some models it is even worth looking twice. Sculpture and energy source are not far apart. Let us advise you and find the radiator that integrates perfectly into your home.",
        imgUrl: null
      }],
    },
    {
      id: 3,
      slug: "bathroom",
      title: "Bathroom",
      short: "3D plans, heating, electrical, tiles, carpentry, painting, saunas, infrared cabins, whirlpools, spa, showers and Turkish baths, solariums ... We have everything for your bathroom.",
      content: [
        {
          title: "Realization",
          text: "Realization of your complete bathroom in connection with the following areas:" +
            "\n" +
            "3D plans, heating, electrical, tiles, carpentry, paint, saunas, infrared cabins, whirlpools, spas, showers and Turkish baths as well as solariums",
          imgUrl: "/images/services/bathroom/architecture-bathroom-bathtub-1454804.jpg"
        },
        {
          title: "Your bathroom",
          text: "An invigorating feeling to wake up in the morning and know to step into an oasis of wellbeing. The bathroom is the first room we enter after getting up on the day. For a long time it was given little importance. We spend a lot of time in it to optimally prepare for the day ahead. With us you can be sure that you will not experience any nasty surprises during the renovation. We assure you a \"hand in hand bathroom\" that will be a magical permanent experience from renovation to completion.",
          imgUrl: "/images/services/bathroom/architecture-bathroom-contemporary-280209.jpg"
        }
      ],
    },
    {
      id: 4,
      slug: "electric",
      title: "Electrical sector",
      short: "Whether renovations or new buildings, we deliver everything to do with electrics.",
      content: [
        {
          title: "Smart home control",
          text: "Our newly structured electrical department offers you individual solutions for your entire house. Whether renovations or new buildings, we deliver everything to do with electrics." +
            "\n" +
            "Here we focus on universal and intelligent home control with the future-proof KNX system. We have also specialized in wireless radio technology. Our technicians will be happy to advise you on all matters relating to lighting, blinds and electric shutters, and controlling the temperature and climate in your four walls.",
          imgUrl: "/images/services/electric/cables-close-up-computer-257736.jpg"
        },
        {
          title: "Security",
          text: "In our day and age, the issue of security is also becoming increasingly important. For a feeling of security in your own four walls, we offer you various alarm system solutions. Smoke alarms are also part of the protection. The invisible guardian angel is switched in such a way that the smoke alarms do not attract attention and still offer maximum protection.",
          imgUrl: "/images/services/electric/door-handle-key-279810.jpg"
        },
        {
          title: "Sound systems",
          text: "Communication and sound systems are not neglected either. When it comes to music, we offer wall installations that promise perfection in sound and design. We also connect all multimedia components, network technology and more.",
          imgUrl: null
        }],
    },
    {
      id: 5,
      slug: "fireplace",
      title: "Heating and fireplace",
      short: "We realize your heating installation and repair of the chimney.",
      content: [{
        title: null,
        text: "We realize your heating installation and repair of the chimney. We guarantee that we are always up to date with the latest technology. We analyze your existing heating systems and find the most economical and suitable solution for you. We also protect you from condensation problems. We give valuable tips and are happy to advise you, because nothing is more relaxing than listening to the crackling of a burning fire.",
        imgUrl: null
      }],
    },
    {
      id: 6,
      slug: "wellness",
      title: "Wellness",
      short: "So that you can relax after a day at work, we offer you the complete solution for swimming pools, saunas and whirlpools as well as tubs and showers with a massage function.",
      content: [
        {
          title: "Oasis of well-being",
          text: "So that you can relax after a day at work, we offer you the complete solution for swimming pools, saunas and whirlpools as well as tubs and showers with a massage function." +
            "\n" +
            "Let us advise you in the area of spa and wellness.",
          imgUrl: "/images/services/wellness/architektur-brucke-entspannung-268092.jpg",
        }
      ],
    },
    {
      id: 7,
      slug: "renewable-energy",
      title: "Renewable energy and the environment",
      short: "Solar installations for ecological heating systems with utility water. From solar thermal systems based on tubes and flat-plate collectors for hot water preparation and heating support to photovoltaic modules for power generation, we provide you with an all-round package.",
      content: [
        {
          title: "Solar installations",
          text: "Solar installations for ecological heating systems with utility water. From solar thermal systems based on tubes and flat-plate collectors for hot water preparation and heating support to photovoltaic modules for power generation, we provide you with an all-round package.",
          imgUrl: "/images/services/renewable-energy/alternative-alternative-energy-clean-159397.jpg",
        },
        {
          title: "Heat pumps based on renewable energies",
          text: "With our various partners, we can also do the core drilling for you. Because nothing is more effective than using the heat from nature. Here, too, we have a wide range of variants for you: From compact devices that are particularly suitable for passive houses to cascade solutions with several hundred kilowatts of power, we will switch you to the method that is best for you.",
          imgUrl: null,
        },
        {
          title: "Pellets and wood heating",
          text: "In order to guarantee a heat supply from houses to complex systems, Buffadini offers you complete solutions for your heat supply. As a renewable raw material, wood is an environmentally friendly alternative to oil or gas. You can ask us about government measures for the use of renewable energies and information on funding opportunities. Our technicians are always up to date with the latest funding opportunities.",
          imgUrl: null,
        },
        {
          title: "Air conditioning",
          text: "An air conditioning system has the task of conditioning a room with the help of the supply air and the air duct. The classification is based on the regulated thermodynamic functions for the supply air. Buffadini develops simple ventilation concepts for you, as well as ventilation systems with heating or air heating functions and full air conditioning systems.",
          imgUrl: null,
        },
        {
          title: "Interventions with our cameras",
          text: "• We also offer you the opportunity to generate reports through our various types of cameras." +
            "• Intervention by thermographic camera (finding out leaks in heating pipes)\n" +
            "• Intervention by tube camera (finding out any blockages that have arisen or are arising)",
          imgUrl: null,
        }
      ],
    },
    {
      id: 8,
      slug: "swimming-pool",
      title: "Your swimming pool",
      short: "Having their own swimming pool is a dream for many. We realize it with you.",
      content: [
        {
          title: null,
          text: "Having their own swimming pool is a dream for many. We realize it with you. We support you with your construction project from the 3D planning to the completion. Find the shape, size, tiles and the general conditions together with us. We bring the technical knowledge, you your ideas." +
            "\n" +
            "And beyond that, we are there for you with our maintenance service. We take care of the weekly cleaning for you. With us you have the possibility to book the revision and maintenance directly. Experienced specialists take care of your concerns so that you can relax at home without worries. We also take care of maintenance during your holiday so that you can relax without worry.",
          imgUrl: "/images/services/swimming-pool/architektur-baume-dach-1488267.jpg"
        }
      ],
    },
    {
      id: 9,
      slug: "tiling",
      title: "Tiles",
      short: "With creativity and skill, our company succeeds in creating not only a hygienic but also a balanced ambience through individual tiles.",
      content: [
        {
          title: null,
          text: "With creativity and skill, our company succeeds in creating not only a hygienic but also a balanced ambience through individual tiles. Unusual tiles, trendy colors or stylish formats: we offer you in our wide range" +
            "\n" +
            "- Highly polished tiles\n" +
            "- Fine pottery\n" +
            "- Natural stone for both exteriors and interiors\n" +
            "- Mosaic creation\n" +
            "\n" +
            "We will be very glad to offer you a date when we can counsel you so we can hunt down together tiles that will suit your own personal style.",
          imgUrl: "/images/services/tiling/blumenvase-boden-couch-280232.jpg"

        }
      ],
    },
    {
      id: 10,
      slug: "suitable-for-disabled",
      title: "The no hands bathroom",
      short: "Especially in the bathroom, people want peace and privacy. In order to ensure that this area also offers the elderly and people with disabilities all the comforts, there are a few things to consider when planning.",
      content: [
        {
          title: null,
          text: "In the bathroom in particular, people want peace and privacy. In order to ensure that this area also offers the elderly and people with disabilities all the comforts, there are a few things to consider when planning. However, you should not only think about the necessary individual needs. A handicapped accessible bathroom doesn't have to give the feeling of being in a hospital or a rehab center. Even in a barrier-free bathroom, you can feel good and enjoy all the comforts. However, the primary focus is on usability. What use is the most beautiful bathroom if the wheelchair does not fit through the door or cannot be turned in the bathroom?" +
            "\n" +
            "In our 3D program, we can display your handicapped-accessible bathroom on the monitor in advance and simulate various variants and options. Together we will find the optimal solution that combines functionality and design.",
          imgUrl: "/images/services/suitable-for-disabled/suitable-for-disabled.jpg"
        }
      ],
    }
  ]
};

export function getServiceByLangAndSlug(lang, slug: string) {
  let result = null;

  const translatedServices = services[lang.code] || [];

  translatedServices.forEach((service) => {
    if (service.slug === slug) {
      result = service;
    }
  });

  return result;
}

export function getServicesByLang(lang) {
  return services[lang.code] || [];
}
